import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  private apiUrl: string = environment.apiUrl + '/users/me';

  constructor(private http: HttpClient) {}

  fetchUserPreferences(): Observable<any> {
    const url = this.apiUrl;
    return this.http.get<any>(url);
  }

  fetchUserPermissions(): Observable<any> {
    const url = this.apiUrl;
    return this.http.get<any>(url + '/permissions');
  }
}
