import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { makeAutoObservable } from 'mobx';
import { forkJoin, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { UserPreferenceService } from '../services/user-preference.service';
import { UserMinimalDTO } from '../../features/organization/users/dto/user.dto';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserPreferenceStore {
  user: UserMinimalDTO;
  languageKey: string = 'en';
  userPermissions: any;

  constructor(
    private translateService: TranslateService,
    private userPreferenceService: UserPreferenceService
  ) {
    makeAutoObservable(this);
  }

  /**
   * Initialize user preferences and permissions.
   * @returns Observable<void>
   */
  initialize(): Observable<void> {
    return forkJoin({
      preferences: this.userPreferenceService.fetchUserPreferences(),
      permissions: this.userPreferenceService.fetchUserPermissions(),
    }).pipe(
      tap(({ preferences, permissions }) => {
        this.setUserPreferences(preferences);
        this.setUserPermission(permissions);
        this.userPermissions = permissions
      }),
      map(() => void 0) // Ensure the observable emits `void`
    );
  }

  private setUserPreferences(preferences: any): void {
    // Set language preference
    this.languageKey = preferences?.language || this.languageKey;
    this.translateService.setDefaultLang(this.languageKey);

    // Set user details
    this.user = {
      id: preferences.id,
      first_name: preferences[`first_name_${this.languageKey}`],
      last_name: preferences[`last_name_${this.languageKey}`],
      email: preferences.email,
      profile_image_path: preferences[`profile_image_path`],
      initial: preferences[`initial`],
      color: preferences[`color`],
    };
  }

  private setUserPermission(permissions: any) {}
}
